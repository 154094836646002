import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import ContactForm from '../components/Contact/ContactForm'
const Contact = () => {
    return (
         <Layout>
                <Navbar />
                <div className="pt-100"></div>
                <div className="pt-100 mobile-only"></div>
                <ContactForm />
            {/* <div className="text-center">
            <h3>Email:</h3>
            <p><a href="mailto:support@tafoma.com"><u>support@tafoma.com</u></a></p>
            </div> */}
                <Footer />
         </Layout>
    );
}

export default Contact